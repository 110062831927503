import "fontsource-open-sans";
import "fontsource-roboto-slab";
import styled from "styled-components";

import Header from "../header";
import Footer from "../footer";
import GlobalStyle from "../../styles/global";
import { Wrapper } from "./styles";

const ContentsContainer = styled.div`
  padding-top: 80px;
  padding-bottom: 250px;
  background-color: #1a1a1a;
  flex-grow: 1;
`;

const FooterContainer = styled.div`
  flex-grow: 0;
`;

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <Header />
        <ContentsContainer>{children}</ContentsContainer>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </Wrapper>
    </>
  );
};

export default Layout;
