import { ChangeEventHandler } from "react";
import styled from "styled-components";

type Props = {
  label: string;
  type: "text" | "email";
  name: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const Wrapper = styled.div`
  text-align: left;
  label {
    font-family: "Roboto Slab", serif;
    font-size: 14px;
    font-weight: 400;
    display: block;
  }

  input {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    margin-top: 20px;
    padding: 10px 15px;
    width: 100%;
  }
`;

const TextField = ({ label, type, name, value, onChange }: Props) => {
  return (
    <Wrapper>
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
      />
    </Wrapper>
  );
};

export default TextField;
