import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";

export const Container = styled.div<{ blockStyle: string }>`
  position: relative;
  min-height: 110vh;
  padding-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
  overflow: hidden;

  ${({ blockStyle }) =>
    blockStyle === "leaves" &&
    css`
      div.first,
      div.second {
        display: none;
      }

      svg {
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        padding-left: 0;
        padding-right: 0;

        div.first,
        div.second {
          display: block;
          position: absolute;
          top: 0;
          width: 150px;
        }

        div.first {
          left: -50px;
        }

        div.second {
          right: -70px;
        }
      }

      @media screen and (min-width: 1024px) {
        div.first,
        div.second {
          display: block;
          width: 250px;
        }

        div.first {
          left: -80px;
        }

        div.second {
          right: -100px;
        }
      }

      @media screen and (min-width: 1300px) {
        div.first,
        div.second {
          display: block;
          width: 320px;
        }

        div.first {
          left: -100px;
        }

        div.second {
          right: -130px;
        }
      }
    `}
`;

export const BodyContainer = styled.div`
  text-align: center;
  max-width: 500px;
  margin: 20px auto 0;

  div,
  p,
  span {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    color: #fefefe;
  }
`;

export const HeadingWrapper = styled.div`
  text-align: center;
  color: #e3e3e3;
  max-width: 500px;
  margin: 0 auto;
`;

export const StyledCtaLink = styled(Link)`
  padding: 8px 25px;
  width: 100%;
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  color: #fefefe;
  background-color: #0471de;
  opacity: 0.87;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
`;

export const StyledScrollCtaLink = styled(ScrollLink)`
  padding: 8px 25px;
  width: 100%;
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  color: #fefefe;
  background-color: #0471de;
  opacity: 0.87;
  border-radius: 3px;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const CtaWrapper = styled.div`
  margin-top: 60px;
`;
