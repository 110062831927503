import Logo from "../logo";
import { Container, OuterContainer, LogoWrapper } from "./styles";

function Footer() {
  return (
    <OuterContainer>
      <Container>
        <LogoWrapper>
          <Logo size={0.6} />
        </LogoWrapper>
        <div>
          <p>
            Copyright © 2023 Freshweb Ltd - Freshweb Ltd is a company registered
            in England and Wales (company no. 10865421)
          </p>
          <p>
            1-2 Harbour House Harbour Way, Shoreham By Sea, West Sussex, United
            Kingdom, BN43 5HZ
          </p>
        </div>
      </Container>
    </OuterContainer>
  );
}

export default Footer;
