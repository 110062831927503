import ContactForm from "../components/contact-form";
import Heading3 from "../components/heading3";
import { HeadingWrapper } from "../components/content-block/styles";
import { Block } from "../styles/global";

const content = {
  blocks: [
    {
      title: "Breathing life into your technology",
      id: "intro",
      body: (
        <>
          <p>
            At freshweb, we provide software development and architecture
            services that will set you up for success.
          </p>
        </>
      ),
      cta: {
        text: "Our mission",
        type: "scrollTo",
        to: "our-mission",
      },
      style: "leaves",
      backgroundColour: "#1A1A1A",
      nextBlockElement: "our-mission",
    },
    {
      title: "Our Mission",
      id: "our-mission",
      body: (
        <>
          <p>
            We strive to build best-in-class solutions that support your company
            for the long haul.
          </p>
          <p>
            Beyond building best-in-class solutions, we aim to provide guidance
            and tools that are essential for continued success.
          </p>
          <p>
            We believe clear communication, transparency and agility are the
            foundation of a successful software project; this is key to all
            projects we take on.
          </p>
        </>
      ),
      cta: {
        text: "Our services",
        type: "scrollTo",
        to: "our-services",
      },
      style: "leaves",
      backgroundColour: "#0A0844",
      prevBlockElement: "intro",
      nextBlockElement: "our-services",
    },
    {
      title: "Our Services",
      id: "our-services",
      body: (
        <>
          <p>
            We provide system design and development services across the stack.
          </p>
          <p>
            This includes design and development of full-stack web solutions
            including cloud infrastructure provisioning.
          </p>
          <p>
            We also build and design complex backend systems primarily revolved
            around serverless workflows and event-driven architecture patterns.
          </p>
          <Block>
            <HeadingWrapper>
              <Heading3>Frontend</Heading3>
              <p>
                We use React to build frontend Web UIs. Some frameworks we work
                with include Next.js, Gatsby and Remix.
              </p>
            </HeadingWrapper>
          </Block>
          <Block>
            <HeadingWrapper>
              <Heading3>Backend & Infrastructure</Heading3>
              <p>
                We work with NodeJS/TypeScript, Python and Go to build the
                backend for solutions. We primarily use AWS to deploy and run
                systems.
              </p>
              <p>
                When it comes to databases, we have experience working with
                relational and NoSQL databases and will use the best fit for a
                given project.
              </p>
            </HeadingWrapper>
          </Block>
        </>
      ),
      cta: {
        text: "Get in touch",
        type: "scrollTo",
        to: "get-in-touch",
      },
      style: "leaves",
      backgroundColour: "#1A1A1A",
      prevBlockElement: "our-mission",
      nextBlockElement: "get-in-touch",
    },
    {
      title: "Get in touch",
      id: "get-in-touch",
      body: (
        <>
          <p>We're all ears.</p>
          <ContactForm />
        </>
      ),
      style: "leaves",
      backgroundColour: "#0A0844",
      prevBlockElement: "our-services",
    },
  ],
} as const;

export default content;
