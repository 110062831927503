import { ChangeEventHandler } from "react";
import styled from "styled-components";

type Props = {
  label: string;
  name: string;
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
};

const Wrapper = styled.div`
  text-align: left;

  label {
    font-family: "Roboto Slab", serif;
    font-size: 14px;
    font-weight: 400;
    display: block;
  }

  textarea {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    margin-top: 20px;
    padding: 10px 15px;
    width: 100%;
    min-height: 200px;
  }
`;

const TextArea = ({ label, name, value, onChange }: Props) => {
  return (
    <Wrapper>
      <label htmlFor={name}>{label}</label>
      <textarea name={name} id={name} value={value} onChange={onChange} />
    </Wrapper>
  );
};

export default TextArea;
