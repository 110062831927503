import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Events } from "react-scroll";

import content from "../content/home";

import { Wrapper } from "../styles/pages/index-styles";
import Layout from "../components/layout";
import ContentBlock from "../components/content-block";

const IndexPage = () => {
  const [inProgrammaticScroll, setInProgrammaticScroll] = useState(false);

  useEffect(() => {
    Events.scrollEvent.register("begin", () => {
      setInProgrammaticScroll(true);
    });
    Events.scrollEvent.register("end", () => {
      setInProgrammaticScroll(false);
    });
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, [setInProgrammaticScroll]);

  return (
    <Layout>
      <Helmet>
        <title>
          Freshweb - Solution Architecture & Software Development Services
        </title>
      </Helmet>
      <Wrapper>
        <main>
          {content.blocks.map((block) => (
            <ContentBlock
              key={block.id}
              inProgrammaticScroll={inProgrammaticScroll}
              {...block}
            />
          ))}
        </main>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
