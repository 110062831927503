import { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, ValidationError } from "@formspree/react";

import TextArea from "../forms/text-area";
import TextField from "../forms/text-field";
import PrimaryButton from "../buttons/primary";
import { AnimatePresence, motion } from "framer-motion";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

// Text fields contain divs which can not be in paragraphs.
const StyledParagraph = styled.div`
  margin: 40px 0;
`;

const ContactForm = () => {
  // todo: parameterise contact form ID. (with .env or similar)
  const [state, handleSubmit] = useForm("xayzwzad");
  const [fields, setFields] = useState<Record<string, string>>({
    name: "",
    email: "",
    message: "",
    "bot-field": "",
  });
  const [showSuccess, setShowSuccess] = useState(false);

  const handleChange = (event) =>
    setFields((fields) => ({
      ...fields,
      [event.target.name]: event.target.value,
    }));

  useEffect(() => {
    if (state.succeeded) {
      setShowSuccess(true);
    }
  }, [state.succeeded]);

  useEffect(() => {
    if (!showSuccess) {
      setFields({ name: "", email: "", message: "" });
    }
  }, [showSuccess, setShowSuccess]);

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };
  return (
    <>
      <AnimatePresence>
        {!showSuccess && (
          <motion.form
            name="contact"
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ duration: 0.3 }}
            onSubmit={handleSubmit}
          >
            <StyledParagraph>
              <TextField
                type="text"
                label="Your Name"
                name="name"
                value={fields.name}
                onChange={handleChange}
              />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
            </StyledParagraph>
            <StyledParagraph>
              <TextField
                type="email"
                label="Your Email"
                name="email"
                value={fields.email}
                onChange={handleChange}
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </StyledParagraph>
            <StyledParagraph>
              <TextArea
                label="Message"
                name="message"
                value={fields.message}
                onChange={handleChange}
              />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
            </StyledParagraph>
            <StyledParagraph>
              <PrimaryButton
                type="submit"
                disabled={
                  !fields.name?.trim() ||
                  !fields.email?.trim() ||
                  !fields.message?.trim() ||
                  state.submitting
                }
              >
                Send
              </PrimaryButton>
            </StyledParagraph>
          </motion.form>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showSuccess && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ duration: 0.3 }}
          >
            <StyledParagraph>
              We've received your message, we'll be in touch soon!
            </StyledParagraph>
            <StyledParagraph>
              <PrimaryButton
                type="button"
                disabled={false}
                onClick={() => setShowSuccess(false)}
              >
                Send another
              </PrimaryButton>
            </StyledParagraph>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ContactForm;
