import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Events } from "react-scroll";

import Logo from "../logo";
import navItems from "../../content/navigation";
import {
  Container,
  LogoWrapper,
  DesktopNavWrapper,
  MobileNavWrapper,
  NavItems,
  StyledNavLink,
  StyledScrollNavLink,
  NavListItem,
  BarsWrapper,
  HeaderBlock,
} from "./styles";

const Header = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  useEffect(() => {
    Events.scrollEvent.register("end", () => {
      setShowMobileNav(false);
    });
    return () => Events.scrollEvent.remove("end");
  }, []);

  const renderNavItems = () => {
    return (
      <NavItems>
        {navItems.map((navItem) => (
          <NavListItem key={navItem.title} whileHover={{ opacity: 1 }}>
            {navItem.type === "internalLink" && (
              <StyledNavLink to={navItem.to}>{navItem.title}</StyledNavLink>
            )}
            {navItem.type === "scrollTo" && (
              <StyledScrollNavLink
                to={navItem.to}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {navItem.title}
              </StyledScrollNavLink>
            )}
          </NavListItem>
        ))}
      </NavItems>
    );
  };

  const mobileNavVariants = {
    hidden: { translateY: -232 },
    visible: { translateY: 0 },
  };

  return (
    <Container>
      <HeaderBlock>
        <LogoWrapper whileHover={{ scale: 1.05 }}>
          <Link to="/">
            <Logo />
          </Link>
        </LogoWrapper>
        <BarsWrapper whileHover={{ opacity: 1 }}>
          <FontAwesomeIcon
            icon={faBars}
            style={{ fontSize: "24px" }}
            size="2x"
            color="#ffffff"
            onClick={() => setShowMobileNav((prevState) => !prevState)}
          />
        </BarsWrapper>
        <DesktopNavWrapper>{renderNavItems()}</DesktopNavWrapper>
      </HeaderBlock>
      <AnimatePresence>
        {showMobileNav && (
          <MobileNavWrapper
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ duration: 0.4 }}
            variants={mobileNavVariants}
          >
            {renderNavItems()}
          </MobileNavWrapper>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default Header;
