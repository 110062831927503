import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";

export const Container = styled.header`
  position: fixed;
  height: 80px;
  top: 0;
  width: 100vw;
  z-index: 100;
`;

export const HeaderBlock = styled.div`
  position: relative;
  height: 80px;
  width: 100%;
  background-color: #000000;
  z-index: 2;
`;

export const LogoWrapper = styled(motion.div)`
  margin: 0 auto;
  width: 150px;

  svg {
    width: 100%;
  }
`;

export const MobileNavWrapper = styled(motion.nav)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 20px;
  text-align: center;
  background-color: #121212;
  z-index: 1;

  @media screen and (min-width: 860px) {
    display: none;
  }
`;

export const DesktopNavWrapper = styled(motion.nav)`
  display: none;
  position: absolute;
  right: 50px;
  top: 5px;
  height: 80px;
  padding: 0;
  margin: 0;
  text-align: left;
  background-color: transparent;
  @media screen and (min-width: 860px) {
    display: flex;
    align-items: center;
  }
`;

export const StyledNavLink = styled(Link)`
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  color: #fefefe;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledScrollNavLink = styled(ScrollLink)`
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  color: #fefefe;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const NavListItem = styled(motion.li)`
  display: inline-block;
  opacity: 0.9;
`;

export const NavItems = styled.ul`
  margin: 0 auto;
  padding: 0;
  ${NavListItem} {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 860px) {
    margin: 0;
  }
`;

export const BarsWrapper = styled(motion.div)`
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
  opacity: 0.9;
  @media screen and (min-width: 860px) {
    display: none;
  }
`;
