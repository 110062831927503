import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }
  html {
    box-sizing: border-box;
  }
  * {
    box-sizing: inherit;
  }
  *:before, *:after {
    // Due to styling decisions for the components in the ui library,
    // before and after pseudo-elements should size based on contents.
    box-sizing: content-box;
  }
`;

export const Block = styled.div`
  margin: 50px auto;
`;

export default GlobalStyle;
