import { ReactNode, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Element, animateScroll as scroller } from "react-scroll";
import usePrevious from "../../hooks/use-previous";
import Heading1 from "../heading1";
import Leaf from "../icons/leaf";

import {
  Container,
  BodyContainer,
  HeadingWrapper,
  StyledCtaLink,
  StyledScrollCtaLink,
  CtaWrapper,
} from "./styles";

type CTA = {
  text: string;
  type: "externalLink" | "scrollTo" | "internalLink";
  to: string;
};

type Props = {
  title: string;
  id: string;
  body: ReactNode;
  style: string;
  nextBlockElement?: string;
  prevBlockElement?: string;
  cta?: CTA;
  backgroundLayer?: ReactNode;
  backgroundColour: string;
  inProgrammaticScroll: boolean;
};

const ContentBlock = ({
  title,
  id,
  body,
  cta,
  nextBlockElement,
  prevBlockElement,
  style,
  backgroundColour,
  backgroundLayer,
  inProgrammaticScroll,
}: Props) => {
  const { ref, inView, entry } = useInView({
    triggerOnce: false,
    rootMargin: "-100px 0px",
    // 10% of the next section should be in viewport to trigger
    // intersection event so we can snap to the section.
    threshold: 0.1,
  });
  const wasInView = usePrevious(inView);
  const previousY = usePrevious(entry?.boundingClientRect?.y);

  useEffect(() => {
    if (wasInView && !inView && !inProgrammaticScroll) {
      const scrollToElemName =
        entry.boundingClientRect.y < previousY
          ? nextBlockElement
          : prevBlockElement;

      if (scrollToElemName && entry) {
        const scrollToElem =
          entry.target.ownerDocument.getElementsByName(scrollToElemName);

        const elTop = scrollToElem[0]?.offsetTop ?? 0;

        // todo: fix to work with trackpads and touch devices,
        // only seems to force scroll to top of next block
        // when using a sensitive mouse.
        scroller.scrollTo(elTop - 70, {
          duration: 400,
          smooth: true,
        });
      }
    }
  }, [wasInView, inView, entry, previousY, inProgrammaticScroll]);

  const renderCta = () => {
    return (
      <CtaWrapper>
        {cta.type === "internalLink" ||
          (cta.type === "externalLink" && (
            <StyledCtaLink to={cta.to}>{cta.text}</StyledCtaLink>
          ))}
        {cta.type === "scrollTo" && (
          <StyledScrollCtaLink
            to={cta.to}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {cta.text}
          </StyledScrollCtaLink>
        )}
      </CtaWrapper>
    );
  };

  return (
    <Element name={id}>
      <Container
        id={id}
        blockStyle={style}
        style={{ backgroundColor: backgroundColour }}
      >
        {style === "leaves" && (
          <>
            <motion.div
              className="first"
              initial={{ opacity: 0, translateX: -100 }}
              transition={{ duration: 0.4 }}
              animate={{
                opacity: inView ? 1 : 0,
                translateX: inView ? 0 : -100,
              }}
            >
              <Leaf />
            </motion.div>
            <motion.div
              className="second"
              initial={{ opacity: 0, translateX: 100 }}
              transition={{ duration: 0.4 }}
              animate={{
                opacity: inView ? 1 : 0,
                translateX: inView ? 0 : 100,
              }}
            >
              <Leaf />
            </motion.div>
          </>
        )}
        <HeadingWrapper>
          <Heading1>{title}</Heading1>
        </HeadingWrapper>

        <BodyContainer ref={ref}>
          {body} {cta && renderCta()}
        </BodyContainer>
      </Container>
    </Element>
  );
};

export default ContentBlock;
