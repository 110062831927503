import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: #e3e3e3;
  padding: 20px 50px;
`;

export const OuterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
`;

export const LogoWrapper = styled.div`
  padding-right: 30px;
`;
