import styled from "styled-components";

const StyledButton = styled.button<{ disabled: boolean }>`
  padding: 8px 25px;
  width: 100%;
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  color: #fefefe;
  background-color: #0471de;
  opacity: ${({ disabled }) => (disabled ? 0.47 : 0.87)};
  border-radius: 3px;
  border: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.47 : 1)};
  }
`;

export default StyledButton;
