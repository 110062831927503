type Props = {
  className?: string;
};

const Leaf = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="441px"
      height="701px"
      viewBox="0 0 441 701"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>LeafIcon</title>
      <g
        id="LeafIcon"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="38899011"
          transform="translate(0.208333, 0.125000)"
          fill="#A4A4A4"
        >
          <path
            d="M329.964455,205.228852 C253.420119,152.190494 121.959238,136.082422 24.381257,1.42108547e-14 C24.381257,1.42108547e-14 -64.8524632,259.38697 98.1051988,421.22631 C216.397085,538.663021 329.594606,523.844371 329.594606,523.844371 C329.594606,523.844371 389.529162,408.351487 112.159444,197.218443 C112.159444,197.218443 507.112371,339.895819 334.803505,610.448886 C296.75444,670.191266 263.095835,701.09375 263.095835,701.09375 C295.883506,685.036577 331.727797,656.523885 353.3532,630.299188 C430.658708,536.561652 512.915414,331.999325 329.964455,205.228852"
            id="Fill-32"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Leaf;
